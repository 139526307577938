.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 30px;
}

.search-container {
  margin-bottom: 10px;
  width: 30%;
}

.recipes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
}
